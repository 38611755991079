import { reactive } from "vue";

import axios from "@/utils/axios";

interface IColumn {
  title: string;
  dataIndex: string;
  key: string;
  width?: number;
}

interface State {
  imageRows: any[];
  videoRows: any[];
  columns: IColumn[];
  selectedKey: Array<string>;
  videoTotal: number;
  imageTotal: number;
}

const state = reactive<State>({
  imageRows: [],
  videoRows: [],
  columns: [
    {
      title: "결과물",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "상태",
      dataIndex: "progress",
      key: "progress",
    },
    {
      title: "타겟페이스",
      dataIndex: "targetFace",
      key: "targetFace",
    },
    {
      title: "시나리오",
      dataIndex: "scenario",
      key: "scenario",
    },
    {
      title: "변환일",
      dataIndex: "finishedAt",
      key: "finishedAt",
    },
  ],
  selectedKey: [],
  imageTotal: 0,
  videoTotal: 0,
});

const getImageApi = async (page = 1) => {
  try {
    const { data } = await axios.get(`media/images/jobs?size=10&page=${page}`);
    state.imageRows = data.data.map((obj: any) => {
      return { ...obj, key: obj.url };
    });
    state.imageTotal = data.total;
  } catch (error) {
    state.imageRows = [];
  }
};

const getVideoApi = async (page = 1) => {
  try {
    const { data } = await axios.get(`media/videos/jobs?size=10&page=${page}`);
    state.videoRows = data.data.map((obj: any) => {
      return { ...obj, key: obj.url };
    });
    state.videoTotal = data.total;
  } catch (error) {
    state.videoRows = [];
  }
};

const setSelectedKey = (_selectedKey: Array<string>) => {
  state.selectedKey = _selectedKey;
};

const downloadFile = async (fileUrl: string, fileName: string) => {
  const data = await axios.get(fileUrl, {
    responseType: "blob",
  });
  let fileType = data.data.type.split("/")[1];
  if (data.data.type === "audio/mpeg") {
    fileType = "mp3";
  }
  const url = window.URL.createObjectURL(new Blob([data.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName + "." + fileType);
  link.style.cssText = "display:none";
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const useContext = () => {
  return { state, getImageApi, getVideoApi, setSelectedKey, downloadFile };
};

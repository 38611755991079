
import { defineComponent, onBeforeMount, ref } from "vue";

import { notification } from "ant-design-vue";

import ScenarioCard from "@/components/ScenarioCard.vue";
import Table from "@/components/table/index.vue";
import { useContext } from "@/components/table/useContext";
import UploadCard from "@/components/UploadCard.vue";
import axios from "@utils/axios";

import FaceMappingCard from "../components/FaceMappingCard.vue";

interface IUploadResult {
  progress: number;
  originFaceId: string;
  urls: string[];
}

export default defineComponent({
  name: "ConvertImages",
  components: { FaceMappingCard, UploadCard, ScenarioCard, Table },
  setup() {
    const fileList = ref<string[]>([]);
    const targetFaceUrl = ref<string>();
    const faceId = ref<string>();

    const faceMapRef = ref();
    const scenarioRef = ref();

    const { state, getImageApi, setSelectedKey } = useContext();

    const onClick = async () => {
      const { targetFaces, selectedIndex } = faceMapRef.value;

      await axios.post(`media/images/${faceId.value}`, {
        targetFaceId: targetFaces[selectedIndex].id,
        scenarioId: scenarioRef.value?.selectedScenario,
      });
      notification.success({
        message: "생성 성공",
        description: "이미지 변환을 성공적으로 완료했습니다.",
      });
      getImageApi();
    };

    const onUpload = async ({ originFaceId, urls }: IUploadResult) => {
      fileList.value = urls;
      faceId.value = originFaceId;
    };

    onBeforeMount(() => {
      setSelectedKey([]);
    });

    return {
      faceMapRef,
      scenarioRef,

      fileList,
      targetFaceUrl,
      state,
      // methods
      onClick,
      onUpload,
    };
  },
});

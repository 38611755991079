
import { defineComponent, reactive } from "vue";

import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

import axios from "@utils/axios";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default defineComponent({
  name: "SignTest",
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const { cookies } = useCookies();
    const router = useRouter();

    let formState = reactive({
      username: "",
      password: "",
    });

    const submit = async () => {
      axios
        .post(`/login`, {
          username: formState.username,
          password: formState.password,
        })
        .then(({ data }) => {
          cookies.set("galT", data.accessToken, "1d");
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.accessToken}`;
          router.push({ name: "images" });
        })
        .catch((e) => {
          Modal.info({
            title: "로그인 실패",
            content: "로그인이 실패하였습니다. 비밀번호를 확인해주세요",
          });
        });
    };

    return {
      formState,
      layout,
      submit,
    };
  },
});


import { defineComponent, ref, computed, watch } from "vue";

import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { useRoute } from "vue-router";

import axios from "@utils/axios";

const getBase64 = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default defineComponent({
  name: "FaceMappingCard",
  components: { PlusOutlined, LoadingOutlined },
  props: {
    mediaType: {
      type: String,
      required: false,
      default: () => "image",
    },
  },
  emits: ["upload"],
  setup(props, { emit }) {
    const route = useRoute();

    const targetFaceUrl = ref<string>();
    const isUploading = ref(false);
    const id = ref("");
    const progress = ref(0);
    const intervalId = ref(0);

    const getFaceProcess = (faceId: string, file: File) => {
      return new Promise(() => {
        intervalId.value = setInterval(() => {
          axios
            .get(`media/${props.mediaType}s/${faceId}/faces`)
            .then(async (res) => {
              progress.value = res.data.progress;
              if (res.data.progress === 100) {
                emit("upload", {
                  progress: res.data.progress,
                  originFaceId: faceId,
                  urls: res.data.urls,
                });
                targetFaceUrl.value = await getBase64(file);
                progress.value = 0;
                clearInterval(intervalId.value);
              }
            })
            .catch(async (err) => {
              clearInterval(intervalId.value);
              let errorObj = {
                title: "에러",
                content: "에러가 발생했습니다.",
              };
              switch (err.response.status) {
                case 500:
                  errorObj = {
                    title: "얼굴 인식 실패",
                    content:
                      "얼굴 인식에 실패하였습니다. 얼굴이 포함된 이미지로 업로드 해주세요.",
                  };
                  break;
                default:
                  break;
              }
              Modal.error(errorObj);
              isUploading.value = false;
              progress.value = 0;
            });
        }, 2500);
      });
    };

    const beforeUpload = (file: File) => {
      targetFaceUrl.value = "";
      emit("upload", {
        progress: 0,
        originFaceId: undefined,
        urls: [],
      });

      isUploading.value = true;
      const formData = new FormData();
      const _file = new File([file], file.name.normalize(), {
        type: file.type,
      });
      formData.append("file", _file);

      axios
        .post(`media/${props.mediaType}s/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; charset=UTF-8",
          },
        })
        .then(async (res) => {
          id.value = res.data.id;
          await getFaceProcess(res.data.id, file);
          isUploading.value = false;
        })
        .catch((e) => {
          console.log(e.response);
          if (props.mediaType === "image") {
            Modal.error({
              title: "이미지 업로드 실패",
              content:
                "이미지 업로드에 실패하였습니다.  이 문제가 지속된다면 관리자에게 문의해주세요.",
            });
          } else {
            Modal.error({
              title: "비디오 업로드 실패",
              content:
                "영상이 30초를 초과하여 업로드에 실패하였습니다. 이 문제가 지속된다면 관리자에게 문의해주세요.",
            });
          }
          isUploading.value = false;
        });
      return false;
    };

    const uploadMediaType = computed(() => {
      if (props.mediaType === "image") {
        return "image/*";
      } else if (props.mediaType === "video") {
        return "video/mp4";
      } else {
        return "*/*";
      }
    });

    const uploadMediaTypeKorean = computed(() => {
      if (props.mediaType === "image") {
        return "이미지";
      } else if (props.mediaType === "video") {
        return "비디오";
      } else {
        return "파일";
      }
    });

    watch(
      () => route.name,
      () => {
        clearInterval(intervalId.value);
      }
    );

    return {
      getBase64,
      progress,
      targetFaceUrl,
      isUploading,
      // methods
      beforeUpload,

      //computed
      uploadMediaType,
      uploadMediaTypeKorean,
    };
  },
});

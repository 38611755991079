
import { computed, defineComponent, ref, watch } from "vue";

import axios from "@utils/axios";

interface IScenario {
  name: string;
  id: number;
}

interface IScenarioOptions {
  label: string;
  value: number;
}

export default defineComponent({
  name: "ScenarioCard",
  props: {
    mediaType: {
      type: String,
      required: false,
      default: () => "image",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const scenarioList = ref<IScenarioOptions[]>([]);
    const selectedScenario = ref<number>();

    const uploadMediaTypeKorean = computed(() => {
      if (props.mediaType === "image") {
        return "이미지";
      } else if (props.mediaType === "video") {
        return "비디오";
      } else {
        return "파일";
      }
    });

    const onClick = () => {
      emit("click", selectedScenario.value);
    };

    const initialize = async () => {
      const type = props.mediaType === "video" ? 1 : 2;

      const { data } = await axios.get(`media/scenarios?type=${type}`);
      scenarioList.value = data.data.map((obj: IScenario) => {
        return {
          value: obj.id,
          label: obj.name,
        };
      });

      selectedScenario.value = scenarioList.value[0].value;
    };

    watch(
      () => props.mediaType,
      () => {
        initialize();
      },
      { immediate: true }
    );

    return {
      scenarioList,
      selectedScenario,
      // method
      onClick,
      // computed
      uploadMediaTypeKorean,
    };
  },
});

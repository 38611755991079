
import { computed, defineComponent, onBeforeMount } from "vue";

import MediaModal from "@/components/MediaModal.vue";
import { dateFormat } from "@utils/format";

import { useContext } from "./useContext";

type Key = string;

export default defineComponent({
  name: "VideoContainer",
  components: {
    MediaModal,
  },
  props: {
    mediaType: {
      type: String,
      required: false,
      default: () => "image",
    },
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    isDownload: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props) {
    const { state, getVideoApi, getImageApi, setSelectedKey, downloadFile } =
      useContext();

    const onSelectChange = (_selectedRowKeys: Key[]) => {
      setSelectedKey(_selectedRowKeys);
    };

    const downloadFiles = async () => {
      for await (const fileKey of state.selectedKey) {
        await downloadFile(
          `/media/${props.mediaType}s/jobs/${fileKey}`,
          fileKey
        );
      }
    };

    const getDateTime = computed(() => {
      return (dateString: string) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return dateFormat(date);
      };
    });

    onBeforeMount(() => {
      if (props.mediaType === "image") {
        getImageApi();
      } else {
        getVideoApi();
      }
    });

    return {
      state,
      dateFormat,
      onSelectChange,
      downloadFiles,
      // computed
      getDateTime,
    };
  },
});

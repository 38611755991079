
import { defineComponent, ref } from "vue";

import axios from "@/utils/axios";

export default defineComponent({
  props: {
    mediaType: {
      type: String,
      required: false,
      default: () => "image",
    },
    src: {
      type: String,
      required: false,
      default: () => "",
    },
    fileKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isOpenModal = ref(false);
    const toggleModal = () => {
      isOpenModal.value = !isOpenModal.value;
    };

    const download = async () => {
      const data = await axios.get(
        `/media/${props.mediaType}s/jobs/${props.fileKey}`,
        {
          responseType: "blob",
        }
      );
      let fileType = data.data.type.split("/")[1];
      if (data.data.type === "audio/mpeg") {
        fileType = "mp3";
      }
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", props.fileKey + "." + fileType);
      link.style.cssText = "display:none";
      document.body.appendChild(link);
      link.click();
      link.remove();
    };
    return {
      props,
      isOpenModal,
      toggleModal,
      download,
    };
  },
});

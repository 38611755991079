
import { defineComponent, ref, watch } from "vue";

import { RightOutlined } from "@ant-design/icons-vue";

import axios from "@/utils/axios";

interface ITargetFace {
  url: string;
  name: string;
  id: string;
}

export default defineComponent({
  name: "FaceMappingCard",
  components: {
    RightOutlined,
  },
  props: {
    faceList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const fileList = ref([]);
    const selectedIndex = ref(0);

    const targetFaces = ref<ITargetFace[]>([]);

    const onSelect = (index: number) => {
      selectedIndex.value = index;
    };

    const getTargetFaces = async () => {
      const { data } = await axios.get("media/target-faces");
      targetFaces.value = data.data;
    };

    watch(
      () => props.faceList,
      () => {
        getTargetFaces();
      }
    );

    return {
      fileList,
      targetFaces,
      selectedIndex,
      // methods
      onSelect,
    };
  },
});


import { defineComponent, onBeforeMount, ref, computed } from "vue";

import { PlusOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

import Table from "@/components/table/index.vue";
import { useContext } from "@/components/table/useContext";
import axios from "@utils/axios";

interface IDefaultResponse {
  id: string;
  userName: string;
  imageCurrentUsageCount: number;
  videoCurrentUsageLength: number;
  imageMaxUsageCount: number;
  videoMaxUsageLength: number;
}

export default defineComponent({
  name: "HomeLanding",
  components: { Table, PlusOutlined },
  setup() {
    const router = useRouter();
    const { state, getVideoApi, getImageApi } = useContext();

    const userData = ref<IDefaultResponse>({
      id: "",
      userName: "",
      imageCurrentUsageCount: 0,
      videoCurrentUsageLength: 0,
      imageMaxUsageCount: 0,
      videoMaxUsageLength: 0,
    });

    const getHHMM = computed(() => {
      return (_minutes: number) => {
        const hours = Math.floor(_minutes / 60);
        const minutes = _minutes % 60;
        if (minutes < 10) {
          return `${hours}:0${minutes}`;
        }
        return `${hours}:${minutes}`;
      };
    });

    const onRoute = (name: string) => {
      router.push({ name });
    };

    onBeforeMount(async () => {
      const { data } = await axios.get("users/me");
      getVideoApi();
      getImageApi();
      userData.value = data;
    });
    return {
      userData,
      state,
      onRoute,
      // computed
      getHHMM,
    };
  },
});


import { defineComponent, ref, watch } from "vue";

import { LoginOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  components: {
    LoginOutlined,
  },
  setup() {
    const collapsed = ref(false);
    const selectedKeys = ref<string[]>([]);
    const breadcrumb = ref("");

    const router = useRouter();
    const route = useRoute();
    const { cookies } = useCookies();

    const onLogout = () => {
      Modal.confirm({
        title: "로그아웃 하시겠습니까?",
        onOk: () => {
          cookies.remove("galT");
          router.push({ name: "sign" });
        },
      });
    };

    const onRoute = (name: string) => {
      router.push({ name });
    };

    watch(
      () => route.name,
      (name) => {
        selectedKeys.value = [name as string];
        breadcrumb.value = route.meta.title as string;
      },
      { immediate: true }
    );

    return {
      collapsed,
      breadcrumb,
      selectedKeys,
      //
      onRoute,
      onLogout,
    };
  },
});

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useCookies } from "vue3-cookies";

import Home from "@pages/Home.vue";
import Images from "@pages/Images.vue";
import Sign from "@pages/Sign.vue";
import Videos from "@pages/Videos.vue";

import DrawerLayout from "../layouts/DrawerLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "default",
    component: DrawerLayout,
    children: [
      {
        path: "/",
        redirect: "/home",
      },
      {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/convert/image",
        name: "images",
        component: Images,
        meta: {
          title: "이미지 변환",
        },
      },
      {
        path: "/convert/video",
        name: "videos",
        component: Videos,
        meta: {
          title: "영상 변환",
        },
      },
    ],
  },
  {
    path: "/sign",
    name: "sign",
    component: Sign,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { cookies } = useCookies();
  if (to.name !== "sign" && !cookies.get("galT")) {
    next("/sign");
  } else if (to.name === "sign" && cookies.get("galT")) {
    next("/home");
  } else {
    next();
  }
});

export default router;
